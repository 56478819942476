import React from "react"

import { GatsbySeo } from 'gatsby-plugin-next-seo'

import Layout from "../components/layout"
import Header1 from "../components/header1"

export default function Imprint() {
  return (
    <Layout>
    <GatsbySeo noindex={true} />
    <section className="bg-white py-20"> 
        <div className="container max-w-5xl mx-auto m-8">
          <Header1 headerText="Thank you for joining our waitlist"/>
        </div>
       
        <div className="max-w-5xl mx-auto m-8 text-gray-500 md:text-xl text-center">
          <p>We will be in touch when it is your turn.</p>
        </div>
      </section>
    </Layout>
  )
}